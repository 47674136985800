import { Iroute } from './interface';

/**
 * @ABS === ACCOUNT
 */
export const ACCOUNT: Iroute = {
  path: 'account',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOGIN: Iroute = {
  path: 'login',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const FORGOT_PASSWORD: Iroute = {
  path: 'forgot-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const FORGOT_PASSWORD_SUCCESS: Iroute = {
  path: 'forgot-password-successfull',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const RESET_PASSWORD: Iroute = {
  path: 'reset-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const RESET_PASSWORD_SUCCESSFULL: Iroute = {
  path: 'reset-success',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const VERIFY_OTP: Iroute = {
  path: 'verify-otp',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === LAYOUT
 */
export const LAYOUT: Iroute = {
  path: 'layout',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

/**
 * @ABS === DASHBOARD
 */
export const DASHBOARD: Iroute = {
  path: 'dashboard',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

/**
 * @ABS === USER_MANAGEMENT
 */
export const USER_MANAGEMENT: Iroute = {
  path: 'user-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const USER_DETAILS: Iroute = {
  path: 'user-details',
  get fullUrl(): string {
    return `${USER_MANAGEMENT.path}/${this.path}`;
  },
};

export const USER_DETAILS_INDIVIDUAL: Iroute = {
  path: 'user-details-individual',
  get fullUrl(): string {
    return `${USER_MANAGEMENT.path}/${this.path}`;
  },
};

export const USER_DETAILS_TABS: Iroute = {
  path: 'user-details-tabs',
  get fullUrl(): string {
    return `${USER_DETAILS.fullUrl}/${this.path}`;
  },
};

export const USER_DETAILS_INDIVIDUAL_TABS: Iroute = {
  path: 'user-details-individual-tabs',
  get fullUrl(): string {
    return `${USER_DETAILS_INDIVIDUAL.fullUrl}/${this.path}`;
  },
};

export const USER_ADS_HISTORY: Iroute = {
  path: 'user-ads-history',
  get fullUrl(): string {
    return `${USER_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};

export const USER_DEALS_HISTORY: Iroute = {
  path: 'user-deals-history',
  get fullUrl(): string {
    return `${USER_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};

export const DEALS_HISTORY_INDIVIDUAL: Iroute = {
  path: 'deals-history-individual',
  get fullUrl(): string {
    return `${USER_DETAILS_INDIVIDUAL_TABS.fullUrl}/${this.path}`;
  },
};

export const BUSINESS_USER_RATINGS_REVIEWS: Iroute = {
  path: 'business-user-ratings-reviews',
  get fullUrl(): string {
    return `${USER_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};

export const INDIVIDUAL_USER_CHECKIN_HISTORY: Iroute = {
  path: 'individual-user-checkin-history',
  get fullUrl(): string {
    return `${USER_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};

export const CHECKIN_HISTORY_INDIVIDUAL: Iroute = {
  path: 'checkin-history-individual',
  get fullUrl(): string {
    return `${USER_DETAILS_INDIVIDUAL_TABS.fullUrl}/${this.path}`;
  },
};



/**
 * @ABS === NOTIFICATION
 */
export const NOTIFICATION: Iroute = {
  path: 'notification',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ADD_NOTIFICATION: Iroute = {
  path: 'add-notification',
  get fullUrl(): string {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === HOTEl MANAGEMNT
 */
export const HOTEL_MANAGEMENT: Iroute = {
  path: 'hotel-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const HOTEL_MANAGEMENT_HOTEL_DETAIL: Iroute = {
  path: 'detail',
  get fullUrl(): string {
    return `${HOTEL_MANAGEMENT.fullUrl}/${this.path}`;
  },
};


/**
 * @ABS === MENU MANAGEMENT
 */
export const MENU_MANAGEMENT: Iroute = {
  path: 'menu-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const MENU_MANAGEMENT_ADD_MENU: Iroute = {
  path: 'add-menu',
  get fullUrl(): string {
    return `${MENU_MANAGEMENT.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === EVENT MANAGEMENT
 */
export const EVENT_MANAGEMENT: Iroute = {
  path: 'event-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EVENT_MANAGEMENT_ADD_EVENT: Iroute = {
  path: 'add-event',
  get fullUrl(): string {
    return `${EVENT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};


/**
 * @ABS === STATIC_CONTENT
 */
export const STATIC_CONTENT: Iroute = {
  path: 'static-content',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const STATIC_CONTENT_PUBLIC: Iroute = {
  path: 'static-content-public',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PRIVACY_POLICY: Iroute = {
  path: 'privacy-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const PRIVACY_POLICY_PUBLIC: Iroute = {
  path: 'privacy-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};

export const TERMS_CONDITIONS: Iroute = {
  path: 'terms-condition',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const TERMS_CONDITIONS_PUBLIC: Iroute = {
  path: 'terms-condition',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const COMMUNITY_GUIDELINES: Iroute = {
  path: 'community-guidelines',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const COMMUNITY_GUIDELINES_PUBLIC: Iroute = {
  path: 'community-guidelines',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const DATA_POLICY: Iroute = {
  path: 'data-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const DATA_POLICY_PUBLIC: Iroute = {
  path: 'data-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const COPYRIGHT_POLICY: Iroute = {
  path: 'copyright-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const COPYRIGHT_POLICY_PUBLIC: Iroute = {
  path: 'copyright-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const FAQS: Iroute = {
  path: 'faqs',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const FAQS_PUBLIC: Iroute = {
  path: 'faqs',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};

export const ADD_FAQ: Iroute = {
  path: 'add',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const EDIT_FAQ: Iroute = {
  path: 'edit',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const TAGS: Iroute = {
  path: 'tags',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const CATEGORY_LISTING: Iroute = {
  path: 'category-listing',
  get fullUrl(): string {
    return `${TAGS.fullUrl}/${this.path}`
  }
}

export const EDIT_CATEGORY: Iroute = {
  path: 'edit-category',
  get fullUrl(): string {
    return `${TAGS.fullUrl}/${this.path}`
  }
}

/**
 * @ABS === PROFILE
 */
export const PROFILE: Iroute = {
  path: 'profile',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const PROFILE_DETAILS: Iroute = {
  path: 'details',
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};
export const PROFILE_EDIT: Iroute = {
  path: 'edit',
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const DETAILS: Iroute = {
  path: 'detal',
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

// export const TERM_CONDITIONS: Iroute = {
//   path: 'terms-&-conditions',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const PRIVACY_POLICY: Iroute = {
//   path: 'privacy-policy',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const ABOUT_US: Iroute = {
//   path: 'about-us',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const DATA_POLICY: Iroute = {
//   path: 'data-policy',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const COMMUNITY_GUIDELINES: Iroute = {
//   path: 'community-guidelines',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const COPYRIGHT_POLICY: Iroute = {
//   path: 'copyright-policy',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const FAQ: Iroute = {
//   path: 'faq',
//   get fullUrl(): string {
//     return `${CMS.fullUrl}/${this.path}`;
//   },
// };
// export const ADD_FAQ: Iroute = {
//   path: 'add-faq',
//   get fullUrl(): string {
//     return `${FAQ.fullUrl}/${this.path}`;
//   },
// };
// export const EDIT_FAQ: Iroute = {
//   path: 'edit-faq',
//   get fullUrl(): string {
//     return `${FAQ.fullUrl}/${this.path}`;
//   },
// };

/**
 * @ABS === SUB_ADMIN
 */

export const SUB_ADMIN: Iroute = {
  path: 'sub-admin',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
// export const ADD_SUB_ADMINS: Iroute = {
//   path: 'add-sub-admins',
//   get fullUrl(): string {
//     return `${FAQ.fullUrl}/${this.path}`;
//   },
// };
// export const EDIT_SUB_ADMINS: Iroute = {
//   path: 'edit-sub-admins',
//   get fullUrl(): string {
//     return `${FAQ.fullUrl}/${this.path}`;
//   },
// };
// export const ADD_ROLES: Iroute = {
//   path: 'add-roles',
//   get fullUrl(): string {
//     return `${FAQ.fullUrl}/${this.path}`;
//   },
// };
export const SUB_ADMIN_LISTING: Iroute = {
  path: 'sub-admin-listing',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const ROLES_LISTING: Iroute = {
  path: 'roles-listing',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const ADD_ROLE: Iroute = {
  path: 'add-role',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};
export const EDIT_ROLE: Iroute = {
  path: 'edit-role',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const DETAILS_ROLE: Iroute = {
  path: 'details-role',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const ADD_SUB_ADMIN: Iroute = {
  path: 'add-subAdmin',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const EDIT_SUB_ADMIN: Iroute = {
  path: 'edit-subAdmin',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

export const DETAILS_SUB_ADMIN: Iroute = {
  path: 'details-subAdmin',
  get fullUrl(): string {
    return `${SUB_ADMIN.path}/${this.path}`;
  },
};

/**
 * @ABS === MARKETING_CAMPAIGN
 */


export const MARKETING_CAMPAIGN: Iroute = {
  path: 'marketing-campaign',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};



export const ALL_ADS_LIST: Iroute = {
  path: 'all-ads-list',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};



export const REQUEST_ADS_LIST: Iroute = {
  path: 'request-ads-list',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
};

export const EXPIRING_SOON_ADS_LIST: Iroute = {
  path: 'expiring-soon-ads-list',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
};


export const ALL_ADS_DETIALS: Iroute = {
  path: 'all-ads-details',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
};

export const REQUEST_ADS_DETAILS: Iroute = {
  path: 'request-ads-details',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
};

export const SPONSORED_ADS_DETIALS: Iroute = {
  path: 'sponsored-ads-details',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
}

export const BOOSTED_ADS_DETIALS: Iroute = {
  path: 'boosted-ads-details',
  get fullUrl(): string {
    return `${MARKETING_CAMPAIGN.path}/${this.path}`;
  },
}


/**
 * @ABS === DEALS_MANAGEMENT
 */


export const DEALS_MANAGEMENT: Iroute = {
  path: 'deals',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ALL_DEALS_LIST: Iroute = {
  path: 'all-deals-list',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const REQUESTED_DEALS_LIST: Iroute = {
  path: 'requested-deals-list',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const GIFT_CARD_LIST: Iroute = {
  path: 'gift-card-list',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const ADD_GIFT_CARD: Iroute = {
  path: 'add-gift-card',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const EDIT_GIFT_CARD: Iroute = {
  path: 'edit-gift-card',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const GIFT_CARD_DETAILS: Iroute = {
  path: 'gift-card-details',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};

export const STATIC_CONTENT_DEALS: Iroute = {
  path: 'static-content-deals',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};
export const DEAL_DETAILS: Iroute = {
  path: 'requested-deals-list',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};
export const DEALS_REDEEMED_USERS_LIST: Iroute = {
  path: 'deals-redeemed-users-list',
  get fullUrl(): string {
    return `${DEALS_MANAGEMENT.path}/${this.path}`;
  },
};
export const DEALS_TERSM_CONDITIONS: Iroute = {
  path: 'terms-condition',
  get fullUrl(): string {
    return `${STATIC_CONTENT_DEALS.fullUrl}/${this.path}`;
  },
};
export const DEALS_HOW_IT_WORKS: Iroute = {
  path: 'how-it-works',
  get fullUrl(): string {
    return `${STATIC_CONTENT_DEALS.fullUrl}/${this.path}`;
  },
};
export const DEALS_BANNER: Iroute = {
  path: 'banner',
  get fullUrl(): string {
    return `${STATIC_CONTENT_DEALS.fullUrl}/${this.path}`;
  },
};



/**
 * @ABS === RATINGS & REVIEWS
 */


export const RATINGS_REVIWES: Iroute = {
  path: 'ratings-reviews',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const RATINGS_REVIWES_LIST: Iroute = {
  path: 'ratings-reviews-list',
  get fullUrl(): string {
    return `${RATINGS_REVIWES.path}/${this.path}`;
  },
};

export const RATINGS_REVIEWS_BUSINESS_LIST: Iroute = {
  path: 'ratings-business-list',
  get fullUrl(): string {
    return `${RATINGS_REVIWES.path}/${this.path}`;
  },
};


/**
 * @ABS === PAGE ACCOUNT MANAGEMENT
 */


export const PAGE_ACCOUNT: Iroute = {
  path: 'page-account',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const REPORTED_CONTENT_LIST:Iroute = {
  path: 'reported-content-list',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}

export const REPORTED_CONTENT_DETAILS:Iroute = {
  path: 'reported-content-details',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}

export const BLOCKED_USER_LIST:Iroute = {
  path: 'blocked-user-list',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}

export const DELETED_USERS:Iroute = {
  path: 'deleted-users',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}

export const DELETED_USER_LIST:Iroute = {
  path: 'deleted-user-list',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}

export const DELETED_USER_DETAIL:Iroute = {
  path: 'deleted-user-detail',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${DELETED_USERS.path}/${this.path}`;
  },
}

export const REPORTED_USER_LIST:Iroute = {
  path: 'reported-user-list',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.path}/${this.path}`;
  },
}



export const REPORTED_CONTENT_DETAILS_TABS: Iroute = {
  path: 'reported-content-details-tabs',
  get fullUrl(): string {
    return `${REPORTED_CONTENT_DETAILS.fullUrl}/${this.path}`;
  },
};


export const COMMENT_REPORT: Iroute = {
  path: 'comment-report',
  get fullUrl(): string {
    return `${REPORTED_CONTENT_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};


export const COMMENT_REPORT_USER_LIST: Iroute = {
  path: 'comment-report-user-list',
  get fullUrl(): string {
    return `${PAGE_ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const CONTENT_REPORT_USER_LIST: Iroute = {
  path: 'post-report',
  get fullUrl(): string {
    return `${REPORTED_CONTENT_DETAILS_TABS.fullUrl}/${this.path}`;
  },
};


/**
 * @ABS === CUSTOMER SUPPORT MANAGEMENT
 */


export const CUSTOMER_SUPPORT: Iroute = {
  path: 'customer-support',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const VIEW_CUSTOMER_SUPPORT: Iroute = {
  path: 'view-customer-support',
  get fullUrl(): string {
    return `${CUSTOMER_SUPPORT.fullUrl}/${this.path}`;
  },
};

export const EDIT_CUSTOMER_SUPPORT: Iroute = {
  path: 'edit-customer-support',
  get fullUrl(): string {
    return `${CUSTOMER_SUPPORT.fullUrl}/${this.path}`;
  },
};


// export const USER_DETAILS_INDIVIDUAL_TABS: Iroute = {
//   path: 'user-details-individual-tabs',
//   get fullUrl(): string {
//     return `${USER_DETAILS_INDIVIDUAL.fullUrl}/${this.path}`;
//   },
// };

export const COMMON_USER_DETAILS_INDIVIDUAL_TABS: Iroute = {
  path: 'common-user-details-individual-tabs',
  get fullUrl(): string {
    return `${USER_DETAILS_INDIVIDUAL.fullUrl}/${this.path}`;
  },
};

export const COMMON_USER_DETAILS_BUSINESS_TABS: Iroute = {
  path: 'common-user-details-business-tabs',
  get fullUrl(): string {
    return `${USER_DETAILS.fullUrl}/${this.path}`;
  },
};



/**
 * @ABS ===  VERSION MANAGEMENT
 */

export const VERSION_MANAGEMENT: Iroute = {
  path: 'version-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const VERSION_LISTING: Iroute = {
  path: 'verison-listing',
  get fullUrl(): string {
    return `${VERSION_MANAGEMENT.path}/${this.path}`;
  },
};


export const ADD_VERSION: Iroute = {
  path: 'add-verison',
  get fullUrl(): string {
    return `${VERSION_MANAGEMENT.path}/${this.path}`;
  },
};

export const EDIT_VERSION: Iroute = {
  path: 'edit-verison',
  get fullUrl(): string {
    return `${VERSION_MANAGEMENT.path}/${this.path}`;
  },
};