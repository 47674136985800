import { Injectable } from '@angular/core';
import { userId } from '../../constants/storage-keys';
import {
  PROFILE,
  UPDATE_DEVICE_TOKEN,
  USER_INFO_API,
} from '../../constants/api-end-point';
import { Router } from '@angular/router';
import { ACCOUNT, DASHBOARD } from '../../constants/routes';
import { HttpService } from '../http/http.service';
import { NotificationService } from 'src/app/features/notification/service/notification.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const app = initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  profileDetail: any;
  _fcmToken: any;
  // unReadNotification: any = 0;

  constructor(
    private _router: Router,
    private _http: HttpService,
    private _notificaton: NotificationService
  ) {}
  setUserToken(token: any) {
    localStorage.setItem('session_token', token);
  }
  get token() {
    return localStorage.getItem('session_token');
  }

  get fcmToken() {
    return this._fcmToken || localStorage.getItem('fcm_token');
  }

  setFcmToken(token: any) {
    this._fcmToken = token;
    localStorage.setItem('fcm_token', token);
  }

  getProfileDetail(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {
        this._http
          .get(PROFILE, '', showLoader)
          .toPromise()
          .then(
            (response: any) => {
              this.profileDetail = response.data;
              resolve(this.profileDetail);
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  }
  logout() {
    localStorage.clear();
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate(['/']);
  }

  loginSuccessfully(response: any, responeFrom = '') {
    this.setUserToken(response.data.accessToken);
    localStorage.setItem(userId, btoa(response.data._id));
    this._router.navigate([DASHBOARD.fullUrl]);
  }

  deviceDetail(info?: number): string {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    switch (info) {
      case 1:
        let deviceToken = this.attachDeviceToken();
        return deviceToken;
      case 2:
        let deviceId = this.randomDeviceId();
        return deviceId;
      case 3:
        return '3';
      default:
        return this.getTimeZone().toString();
    }
  }

  attachDeviceToken() {
    return (Date.now() + Math.floor(Math.random() * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return window.navigator.userAgent.replace(/\D+/g, '');
  }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }


  async updateFireBaseToken() {
    if (this._fcmToken) {
      let reqBody = {
        deviceToken: this._fcmToken,
      };
      this._http
        .post(UPDATE_DEVICE_TOKEN, reqBody, null, false)
        .subscribe((res) => {});
    }
  }

  async getFcmDeviceToken() {
    const messaging = getMessaging(app);
    const fcmToken = await getToken(messaging, {
      vapidKey: environment.firebaseConfig.vapidKey,
    });
    if (fcmToken) {
      this.setFcmToken(fcmToken);
    }
  }

  async requestPermission() {
    try {
      const messaging = getMessaging(app);
      const fcmToken = await getToken(messaging, {
        vapidKey: environment.firebaseConfig.vapidKey,
      });
      if (fcmToken) {
        this.setFcmToken(fcmToken);
        this.updateFireBaseToken();
      } else {
        // console.log(
        //   'No registration token available. Request permission to generate one.'
        // );
      }
    } catch (err) {
      console.log('err in fcm ==>', err);
    }
  }
}
