import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { LoaderComponent } from './components/loader/loader.component';
import { getAnalytics } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { AuthService } from './services/interceptor/auth/auth.service';
import { StorageService } from './services/storage/storage.service';
import { initializeApp } from 'firebase/app';

// const analytics = getAnalytics(app);
const app = initializeApp(environment.firebaseConfig);
const messaging = getMessaging(app);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderComponent,
    ToastNotificationComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'angular-RCC';

  constructor(
    private _authService: AuthService,
    private _storageService: StorageService
  ) {}
  async ngOnInit(): Promise<void>  {
    // this._getDeviceToken();
    if(this._storageService.token){
     await this._storageService.requestPermission()
    }
  }

  // async _getDeviceToken() {
  //   if (Notification.permission == 'default') {
  //     localStorage.removeItem('fcm_token');
  //     Notification.requestPermission().then(async (permission) => {
  //       if (Notification.permission == 'granted') {
  //         localStorage.setItem('permiossionAllowed', 'true');
  //         try {
  //           this._storageService.getFcmDeviceToken();
  //         } catch (err) {
  //           console.log('err In getting fcm token==>', err);
  //         }
  //       } else {
  //         localStorage.setItem('permiossionAllowed', null);
  //         console.log('Notification Permission==>', Notification.permission);
  //       }
  //     });
  //   } else if (Notification.permission == 'granted') {
  //     this._storageService.getFcmDeviceToken();
  //   }
  // }
}
